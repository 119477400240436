<template>
  <!--日历组件-->
  <div class="cd_out p_f">
    <div class="cd_content_out p_a" :class="dateInfo.isFull ? 'full_out' : ''">
      <template v-if="dateInfo.isFull">
        <div class="d_f ali_c full_out_head">
          <img class="cur_p" src="../img/close_sp.png" alt="" @click="close">
        </div>
        <div class="fo_week_out d_f ali_c j_s">
          <div class="fo_week">
            <div class="fo_week_day">{{'周' + $toChinese(new Date(chooseDateArr[0]).getDay(),'week')}}</div>
            <div class="fo_week_date">{{$formatDate(new Date(chooseDateArr[0]),"MM/dd")}}</div>
          </div>
          <div class="cut"></div>
          <div class="fo_week t_r">
            <div class="fo_week_day">{{'周' + $toChinese(new Date(chooseDateArr[1]).getDay(),'week')}}</div>
            <div class="fo_week_date">{{$formatDate(new Date(chooseDateArr[1]),"MM/dd")}}</div>
          </div>
        </div>
      </template>
      <div class="cd_content_head d_f ali_c j_s" v-else>
        <img class="cur_p" src="../img/close_sp.png" alt="" @click="close">
        <div class="csch_title flex_1 t_a" v-if="chooseDateArr[1]">{{$formatDate(new Date(chooseDateArr[0]),"MM/dd")+' 至 '+$formatDate(new Date(chooseDateArr[1]),"MM/dd")}}</div>
        <span class="csch_clear cur_p" @click="resetDate">清除</span>
      </div>
      <van-calendar
        ref="calendar"
        :min-date="minDate"
        :max-date="maxDate"
        @select="selectDate"
        v-model="showCalendar"
        :poppable="poppable"
        type="range"
        color="#20C177"
        :formatter="formatter"
        :show-subtitle="false"
        :style="{ height: (dateInfo.isFull ? 420 : 420)+'px' }"
        :show-confirm="false" />
      <div class="bottom_btn_out t_a p_f d_f ali_c j_s" v-if="dateInfo.isFull">
        <div class="cur_p cd_btn" @click="resetDate">重置</div>
        <div class="cur_p cd_btn ac" @click="saveDate">确定</div>
      </div>
      <div class="cd_btn_out cur_p t_a p_f" @click="saveDate" v-else>保存</div>
    </div>
  </div>
</template>

<script>
  import { Calendar } from 'vant'

  export default {
    name: "chooseDateRange",
    components:{
      'van-calendar':Calendar
    },
    props:{
      dateInfo:{
        type: Object,
        default(){
          return{
            isFull:false,
          }
        }
      },
    },
    data() {
      return {
        showCalendar:true,
        poppable:false,
        cHeight:360,
        minDate:new Date(),
        maxDate:new Date((new Date()).getTime()+(365 / 2 * 24 * 3600 * 1000)),//半年后
        chooseDateArr:[new Date(),new Date((new Date()).getTime()+(24 * 3600 * 1000))],
      }
    },
    created(){
      let dateHas = JSON.parse(sessionStorage.getItem('dateRage'));
      if(dateHas){
        this.chooseDateArr = [new Date(dateHas[0]), new Date(dateHas[1])]
      }
    },
    methods: {
      //点击保存按钮
      saveDate(){
        if(!this.chooseDateArr[1]) return this.$toast('请选择日期');
        this.$emit('saveDate',this.chooseDateArr);
        sessionStorage.setItem('dateRage',JSON.stringify(this.chooseDateArr))
      },
      //点击关闭弹窗
      close(){
        if(!sessionStorage.getItem('dateRage')){
          this.resetDate();
        }
        this.$emit('closeCalendar')
      },
      //点击重置按钮
      resetDate(){
        this.chooseDateArr = [new Date(),new Date((new Date()).getTime()+(24 * 3600 * 1000))];
        this.$refs.calendar.reset();
        sessionStorage.removeItem('dateRage')
      },
      //点击日历选择时间
      selectDate(day){
        this.chooseDateArr = day;
        console.log(this.$formatDate(new Date(day[0]),"MM/dd"))
      },
      //日历时间匹配
      formatter(day) {
        day.bottomInfo = '';
        if(day.type === 'start'){
          day.type = ''
        } else if (day.type === 'end') {
          day.type = ''
        }
        let startTime = new Date(this.$formatDate(new Date(this.chooseDateArr[0]),'yyyy/MM/dd')).getTime();
        let endTime = new Date(this.$formatDate(new Date(this.chooseDateArr[1]),'yyyy/MM/dd')).getTime();
        if(new Date(day.date).getTime() === startTime){
          day.type = 'start'
        }
        if(new Date(day.date).getTime() === endTime){
          day.type = 'end'
        }
        if(new Date(day.date).getTime()<endTime && new Date(day.date).getTime()>startTime){
          day.type = 'middle'
        }
        if (day.type === 'start') {
          day.topInfo = '入住';
        } else if (day.type === 'end') {
          day.topInfo = '离店';
        }
        return day;
      },
    },
  }
</script>

<style scoped lang="scss">
  .cd_out{
    .cd_content_out{
      &.full_out{
        .fo_week_out{
          .fo_week{
            font-size:19px;
            font-weight:500;
            color:rgba(30,30,30,1);
          }
          .fo_week_day{
            margin-bottom: 13px;
          }
          .cut{
            width: 70px;
            background-color: #3E3E3E;
            height: 1px;
          }
          background-color: white;
          margin: 30px 0 40px 0;
          padding: 0 21px;
        }
        .full_out_head{
          height: 51px;
          border-bottom: 1px solid #DEDEDE;
          padding-left: 21px;
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          box-sizing: border-box;
          img{
            height: 17px;
            cursor: pointer;
          }
        }
        height: 100vh;
        padding-top: 51px;
        box-sizing: border-box;
      }
      .cd_btn_out{
        width: 100%;
        bottom: 0;
        left: 0;
        line-height: 48px;
        cursor: pointer;
        border-top: 1px solid #C0C0C0;
        font-size:17px;
        font-weight:bold;
        color:rgba(30,180,111,1);
      }
      .cd_content_head{
        .csch_clear{
          color:rgba(30,180,111,1);
        }
        .csch_title{
          color:rgba(30,30,30,1);
        }
        img{
          width: 13px;
        }
        font-size:17px;
        font-weight:bold;
        border-bottom: 1px solid #C0C0C0;
        padding: 20px 23px;
      }
      padding-bottom: 48px;
      border-radius:3px 3px 0px 0px;
      width: 100%;
      bottom: 0;
      background-color: white;
      animation: goToTop .5s;
    }
    z-index: 200;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.51);
  }
</style>
