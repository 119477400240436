<template>
  <div class="full" id="full" @click="delMenu">
    <div class="top p_r">
      <div class="d_f ali_c top_btn p_f" :style="colorState?'background-color:#20c177;':''">
        <a @click="$router.push('/hotels')" href="javascript:;" class="flex_1"><img class="no_l" src="./img/icon-back.png" alt="返回" /></a>
        <a href="javascript:;"><img src="./img/hi_collect.png" @click="collect" alt="收藏" v-if="hotelInfo.isCollect"/></a>
        <a href="javascript:;"><img src="./img/hi_unCollect.png" alt="收藏" @click="collect"  v-if="!hotelInfo.isCollect"/></a>
        <!--<a href="javascript:;" @click="share()"><img src="./img/icon-share.png" alt="分享" /></a>-->
        <a href="javascript:;" @click="showHeadBox = !showHeadBox"><img src="./img/icon-more.png" alt="菜单" /></a>
        <!--弹窗-->
        <div class="p_a td_head_box_out" v-if="showHeadBox">
          <div class="pointer"><span></span></div>
          <div class="menu">
            <div class="tdhb_item d_f ali_c bb cur_p" @click="$router.push('/')">
              <img src="../home/tickets/tiketDetails/img/td_home.png" >
              <span>回到首页</span>
            </div>
            <div class="tdhb_item d_f ali_c bb cur_p" @click="goIntro('/orderList',1)">
              <img src="../home/tickets/tiketDetails/img/td_order.png" >
              <span>我的订单</span>
            </div>
            <div class="tdhb_item d_f ali_c cur_p" @click="$router.push('/collection')">
              <img src="../home/tickets/tiketDetails/img/td_collect_empty.png" >
              <span>我的收藏</span>
            </div>

          </div>
        </div>
      </div>
      <!-- 轮播 -->
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" style="height: 5rem;">
        <van-swipe-item v-for="(item, index) in hotelInfo.attachments" :key="index">
          <van-image :src="item.url" class="recr_swiper_item_img" fit="cover">
            <template v-slot:error>加载失败</template>
          </van-image>
        </van-swipe-item>
      </van-swipe>
      <div class="album d_f p_a ali_c j_c cur_p" @click="goIntro('/hotelAlbum',0,hotelInfo.sellerId)">
        <div>
          <img src="./img/hi_camera.png" alt="">
        </div>
        <span>{{hotelInfo.attachments.length}} ></span>
      </div>
    </div>
    <div class="hi_content p_r" id="content">
      <!--酒店信息-->
      <div class="card mb_0">
        <div class="top_head d_f ali_b">
          <span class="hotel_name">{{hotelInfo.hotelName}}</span>
          <span class="flex_1">{{hotelInfo.starLevelText}}</span>
          <div class="cur_p" @click="goIntro('/hotelDetail',0,$route.query.sellerId,0)">
            <span>酒店详情</span>
            <img src="./img/hi_detail.png" alt="">
          </div>
        </div>
        <div class="top_item clear" v-if="hotelInfo.tags.split(',').length>2">
          <div v-for="(item,index) in hotelInfo.tags.split(',')" :key="index">{{item}}</div>
        </div>
        <div class="top_addr">
          <div>{{hotelInfo.address}}</div>
          <div class="d_f ali_c">
            <img src="./img/hi_addr.png" alt="">
            <span>{{'距您'+ hotelInfo.distance +'公里'}}</span>
          </div>
        </div>
      </div>
      <div class="hd_content no_bc">
        <van-tabs v-model="active" scrollspy :sticky="!isShowDate" :offset-top="0">
          <van-tab title="预订酒店">
            <div class="card pb0" style="margin-bottom: 15px">
              <div class="c_head d_f ali_b cur_p" @click="isShowDate = true">
                <div class="flex_1">
                  <span>{{dateArr.length>1 ? $formatDate(new Date(dateArr[0]),"MM/dd") : ''}}</span><span class="sub">入住</span>
                  <span> - {{dateArr.length>1 ? $formatDate(new Date(dateArr[1]),"MM/dd") : ''}}</span><span class="sub">退房</span>
                </div>
                <div class="ali_c d_f">
                  <span>共 <span class="green">{{$getDaysBetween(dateArr[0],dateArr[1])}}</span> 晚</span>
                  <img src="./img/hi_detail.png" alt="">
                </div>
              </div>
              <div class="c_types clear" v-if="roomTypeArr.length>0">
                <div :class="acRoomType === index ? 'ac' :''" v-for="(item,index) in roomTypeArr" :key="index" @click="chooseRoomType(item,index)">{{item.roomTypeName}}</div>
              </div>
              <div class="c_items_out">
                <div class="c_item d_f rooms_out" :class="index == roomsArr.length-1? 'bb_none' : ''" v-for="(item,index) in roomsArr"  :key="index">
                  <template v-if="item.roomId">
                    <van-image fit="cover" :src="item.coverImgUrl"  @click="showRoomPop(item,index)"/>
                    <div class="flex_1" @click="showRoomPop(item,index)">
                      <div class="top">{{item.name}}</div>
                      <div class="sub">
                        <span v-if="item.roomType">{{item.roomType.roomExtendAttr.useableArea}}m²</span>
                        <span>{{item.roomTypeName}}</span>
                        <span v-if="item.roomType.window>0">有窗</span>
                      </div>
                      <div class="sub">
                        <span v-if="item.roomType">{{item.roomType.capacity}}人入住</span>
                        <span v-if="item.breakfast>0">含早餐</span>
                      </div>
                      <div class="bot">{{'￥'+(item.minSellPrice ? item.minSellPrice/100 : '--')+'/晚'}}</div>
                    </div>
                    <div class="c_item_right t_a cur_p dis" v-if="item.saleLock == 0 ||item.minStock == 0 || item.days < $getDaysBetween(dateArr[0],dateArr[1])">
                      <div class="t_a cur_p">
                        <div class="r_top">满</div>
                        <div class="r_sun">无房型</div>
                      </div>
                      <span class="num">仅剩2间</span>
                    </div>
                    <div class="c_item_right t_a cur_p" @click="goInfor('/fillInformation',item)" v-else>
                      <div class="t_a cur_p">
                        <div class="r_top">订</div>
                        <div class="r_sun">在线付</div>
                      </div>
                      <span class="num">仅剩{{item.minStock}}间</span>
                    </div>
                  </template>

                </div>
              </div>
            </div>
            <!--设施与服务-->
            <div class="card">
              <div class="top_head d_f ali_b">设施与服务</div>
              <div class="fw_out clear" v-if="InstallationServiceArr.length">
                <div v-for="(item,index) in InstallationServiceArr.slice(0,11)" :key="index">{{item.name}}</div>
                <div v-if="InstallationServiceArr.length>11">...</div>
              </div>
              <div v-else class="t_a fw_out mb_12">暂无相关信息</div>
              <div class="see_more t_a">
                <span class="cur_p" @click="goIntro('/hotelDetail',0,$route.query.sellerId,1)">查看详情 ></span>
              </div>
            </div>
          </van-tab>
          <van-tab title="订房须知">
            <!--订房须知-->
            <div class="card">
              <div class="top_head d_f ali_b">订房须知</div>
              <div class="fw_out clear" v-if="BookingInstructionArr.length">
                <div class="notice ali_c d_f" v-for="(item,index) in BookingInstructionArr" :key="index">
                  <span>{{item.title}}</span>
                  <span class="flex_1">{{item.value}}</span>
                </div>
              </div>
              <div v-else class="t_a fw_out mb_12">暂无相关信息</div>
              <div class="see_more t_a">
                <span class="cur_p" @click="goIntro('/hotelDetail',0,$route.query.sellerId,2)">查看详情 ></span>
              </div>
            </div>
            <!--评价-->
            <div class="card comment_out p_r" v-if="commentArr.length">
              <div class="co_score p_a">
                {{commentArr[0].totalScore.toFixed(1)}} <span>超棒</span>
              </div>
              <div class="co_title">房客评价</div>
              <div>
                <div class="d_f ali_c j_s top_user">
                  <div class="user_logo">
                    <van-image round fit="cover" :src="commentArr[0].headPortrait" />
                  </div>
                  <div>
                    <div class="user_name">{{commentArr[0].nickname}}</div>
                    <!--v-model="2"-->
                    <van-rate
                      v-model="commentArr[0].starScore"
                      :size="12"
                      allow-half
                      color="#20C178"
                      void-icon="star-o"
                      void-color="#20C178"
                    />
                  </div>
                  <div class="flex_1 t_r right_text">
                    <div>消费后评价</div>
                    <div>{{commentArr[0].criticismDate}}</div>
                  </div>
                </div>
                <div class="user_text">{{commentArr[0].content}}</div>
              </div>
              <div class="see_more">
                <span class="cur_p" @click="goIntro('/hotelCommentList',0,hotelInfo.sellerId)">阅读全部{{commentTotal}}条评价</span>
              </div>
            </div>
            <div class="card comment_out p_r" v-else>
              <div class="co_title">房客评价</div>
              <div class="t_a mt_15 mb_12">
                暂无评价
              </div>
            </div>

          </van-tab>
          <van-tab title="附近酒店">
            <div class="card pb0">
              <div class="top_head d_f ali_b mb_0">附近酒店</div>
              <div class="c_items_out" v-if="hotelsAround.length">
                <div class="c_item d_f around" @click="goInner(item)" :class="index === hotelsAround.length-1 ? 'bb_none' : ''" v-for="(item,index) in hotelsAround" :key="index">
                  <van-image fit="cover" :src="item.cover" style="border-radius: 4px;overflow: hidden"/>
                  <div class="flex_1">
                    <div class="top">{{item.hotelName}}</div>
                    <div class="sub">{{'距此酒店'+ (item.distance ? (item.distance/1000).toFixed(2) : '--')+'公里'}}</div>
                    <div class="bot d_f ali_c j_s">
                      <div>
                        <div class="star" v-if="item.starLevelText != '不限'">{{item.starLevelText}}</div>
                      </div>
                      <div class="price">{{'￥'+ (item.minPirce ? item.minPirce/100: 0)+'/晚'}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="t_a pb_20" v-else>暂无酒店</div>
            </div>

          </van-tab>
        </van-tabs>
      </div>
    </div>
    <!--底部按钮-->
    <div class="b_btn">
      <div class="d_f ali_c j_s">
        <a :href="'tel:' + hotelInfo.telephone">
          <img src="./img/hotel_contact.png" alt="">
          <div>问问酒店</div>
        </a>
        <div class="btn_cut flex_1"><div></div></div>
        <div class="btn d_f ali_b cur_p" @click="goTop">
          <img src="./img/hi_house.png" alt="">
          <span>查看房型</span>
          <span>￥{{hotelInfo.minPrice ? hotelInfo.minPrice/100 : 0}}<span class="sub">起</span></span>
        </div>
      </div>
    </div>
    <!--房型弹窗-->
    <room v-if="isShowRoom" @close="isShowRoom = false" :roomPopInfo="roomPopInfo" @goOrder="roomOrder"></room>
    <!--日历弹窗-->
    <chooseDateRange :info="dateInfo" v-if="isShowDatePop" @closeCalendar="isShowDate = false" @saveDate="saveDate"></chooseDateRange>
    <!--未登录-->
    <!--<loginPop v-if="isShowLoginPop" @close="isShowLoginPop = false"></loginPop>-->
  </div>
</template>

<script>
  import room from "./component/room";
  import chooseDateRange from './component/chooseDateRange'
  // import loginPop from './component/unLoginPop'
  import apis from './utils/apis'
  import { Toast, SwipeItem, Swipe, Rate, Image, Tabs, Tab } from 'vant';
  export default {
    name: "hotelInner",
    components:{
      chooseDateRange,
      room,
      'van-swipe-item': SwipeItem,
      'van-swipe': Swipe,
      'van-rate': Rate,
      'van-image': Image,
      'van-tabs': Tabs,
      'van-tab': Tab,
    },
    watch:{
      isShowDate(n,o){
        let _t = this;
        if(n){
          _t.isShowDatePop = true;
          setTimeout(function () {
            document.getElementById('full').scrollTop = 0;
          },100)
        }else{
          _t.isShowDatePop = false
        }
      }
    },
    data() {
      return {
        dateInfo:{
          isFull:false,
        },
        active:0,
        showHeadBox:false,
        isShowDate:false,
        isShowDatePop:false,
        colorState:false,
        isShowRoom:false,
        isShowLoginPop:false,
        dateArr:sessionStorage.getItem('dateRage') ? JSON.parse(sessionStorage.getItem('dateRage')) : [new Date().getTime(),Number(new Date().getTime()+(24*3600*1000))],//选中的日期区间
        infoParam:{
          token:this.$global.token,
          sellerId: this.$route.query.sellerId || 105262, //商家id
          longitude: 103.29, //经度
          latitude: 29.36, //纬度
        },
        hotelInfo:{
          tags:'',
          attachments:[],
        },
        InstallationServiceArr:[],//设施与服务
        roomTypeArr:[],//房型
        BookingInstructionArr:[],//预定须知
        acRoomType:null,//选中房型idx
        roomsArr:[],//选中房型idx
        roomTypeId:null,//选中的房型
        roomPopInfo:{},
        hotelsAround:[],//附近酒店
        commentArr:[],//评价列表
        roomindex:null,//附近酒店
        commentTotal:0,//总评价数量
      }
    },
    mounted(){
      window.addEventListener('scroll',this.scrollFunc,true);
      this.loadLocation();
      this.getInstallationService();
      this.queryRoomTypeList();
      this.getBookingInstruction();
      this.getRooms();
      this.getComment();
    },
    destroyed() { //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
      window.removeEventListener('scroll', this.scrollFunc)
    },
    methods: {
      //获取评论
      getComment(){
        let me = this;
        me.$post({
          url:apis.slicedQueryUserCriticism,
          params: {
            sellerId: me.$route.query.sellerId,
            bizType: 12,
            token: me.$global.token
          }
        }).then(res => {//thirdName
          me.commentTotal = res.data.count;
          me.commentArr = res.data.data;
        });
      },
      delMenu(e){
        if(e.target.alt == '菜单'){

        }else{
          if(this.showHeadBox){
            this.showHeadBox = false
          }
        }
      },
      goInner(row){
        this.$router.push({
          path:'/hotelInner',
          query:{
            sellerId:row.sellerId
          }
        });
        location.reload()
      },
      //获取附近列表
      searchHotelList(row){
        this.$get({
          url: apis.searchHotelList,
          params: {
            isRecommend: 1, //是否推荐
            areaName:this.areaName,
            sortName:'sequence',
            sortOrder:'asc',
            token:this.$global.token,
            lon:row.longitude,
            lat:row.latitude,
            presentHotelId:this.infoParam.sellerId,
            distance:5000,
          }
        }).then(rsp => {
          this.hotelsAround = rsp.data;
        });
      },
      //收藏事件响应事件
      collect(){
        if(!this.$global.token) return this.isShowLoginPop = true;
        this.hotelInfo.isCollect == 1 ? this.cancelCollect() : this.addCollect();
      },
      addCollect(){//添加收藏
        const me = this;
        me.$post({
          url: apis.addCollect,
          params:{
            token:me.$global.token,
            bizType: 3,
            bizId: me.hotelInfo.sellerId
          }
        }).then(rsp => {
          me.hotelInfo.isCollect = 1;

        })
      },
      cancelCollect(){//取消收藏
        const me = this;
        me.$post({
          url: apis.cancelCollect,
          params:{
            token: me.$global.token,
            bizType: 3,
            bizId: me.hotelInfo.sellerId
          }
        }).then(rsp => {
          // Toast('操作成功');
          me.hotelInfo.isCollect = 0;
        });
      },
      goInfor(path,item){
        if(!this.$global.token) return this.isShowLoginPop = true;
        let pointUseSwitch = false; // 是否能使用积分     使用积分可兑换金额
        if(item.pointUseSwitch == 1){
          pointUseSwitch = true
        }
        this.$router.push({
          path:path,
          query:{
            name:this.hotelInfo.hotelName,
            refundNotice:this.hotelInfo.refundNotice,
            r: item.name, // 房间名称
            i: pointUseSwitch, // 能否使用积分
            u: item.creditPointsNum // 单个房间使用积分
          }
        });
        sessionStorage.setItem('roomInfo',JSON.stringify(item));
      },
      //跳转done
      goIntro(path,num,id,type){
        if(num){
          if(!this.$global.token) return this.isShowLoginPop = true;
          sessionStorage.setItem('orderList',1);
        }
        this.$router.push({
          path:path,
          query:{
            orderType:num,
            id:id,
            type:type,
          }
        })
      },
      roomOrder(){
        this.goInfor('/fillInformation',this.roomsArr[this.roomindex])
      },
      //打开房间弹窗done
      showRoomPop(row,index){
        if(row.saleLock == 0 || row.minStock == 0 || row.days < this.$getDaysBetween(this.dateArr[0],this.dateArr[1])){
          this.roomPopInfo.showbtn = false;
        }else{
          this.roomPopInfo.showbtn = true;
        }
        this.roomindex = index;
        this.isShowRoom = true;
        this.roomPopInfo.roomId = row.roomId;
        this.roomPopInfo.showBtn = true;
        this.roomPopInfo.minSellPrice = row.minSellPrice;
        this.roomPopInfo.telephone = this.hotelInfo.telephone;
        this.roomPopInfo.hotelName = this.hotelInfo.hotelName;
        this.roomPopInfo.beginTime = this.dateArr[0];
        this.roomPopInfo.endTime = this.dateArr[1];
      },
      //日历保存done
      saveDate(info){
        this.dateArr = JSON.parse(JSON.stringify(info));
        this.isShowDate = false;
        this.getRooms();
      },
      //获取房间done
      getRooms(){
        let endTime = isNaN(new Date(this.dateArr[1])) ? this.dateArr[1] - 24*3600*1000 : new Date(this.dateArr[1]).getTime()-24*3600*1000 ;
        this.$get({
          url: apis.slicedQueryHotelRoom,
          params: {
            sellerId:this.$route.query.sellerId || 105262,
            beginTime:this.$formatDate(new Date(this.dateArr[0]),'yyyy-MM-dd'),
            endTime:this.$formatDate(new Date(endTime),'yyyy-MM-dd'),
            roomTypeId: this.roomTypeId,
            pageSize:1000,
          }
        }).then(rsp => {
          this.roomsArr = rsp.data;
        });

      },
      //查询预定须知done
      getBookingInstruction(){
        this.$get({
          url: apis.getBookingInstruction,
          params: {
            sellerId:this.$route.query.sellerId || 105262
          }
        }).then(rsp => {
          this.BookingInstructionArr = rsp;
        });
      },
      //点击房型done
      chooseRoomType(row,index){
        this.acRoomType = index;
        this.roomTypeId = row.roomTypeId;
        this.getRooms();
      },
      //房型标签done
      queryRoomTypeList(){
        this.$get({
          url: apis.queryRoomTypeList,
          params: {
            sellerId:this.$route.query.sellerId || 105262
          }
        }).then(rsp => {
          this.roomTypeArr = rsp;
        });
      },
      //查询设施与服务done
      getInstallationService(){
        this.$get({
          url: apis.getInstallationService,
          params: {
            sellerId:this.$route.query.sellerId || 105262
          }
        }).then(rsp => {
          this.InstallationServiceArr = rsp;
        });
      },
      //获取当前经纬度done
      loadLocation() { //获取经纬度
        this.getInfo();
      },
      // 获取酒店info done
      getInfo(){
        this.infoParam.token = this.$global.token;
        this.$get({
          url: apis.findInfo,
          params: this.infoParam
        }).then(rsp => {
          this.hotelInfo = rsp;
          let shareUrl = window.location.href.split('#')[1];
          if(JSON.stringify(this.$route.query) === '{}'){
            shareUrl = shareUrl+'?share=1'
          }else{
            shareUrl = shareUrl+'&share=1'
          }
          this.getShareInfo(
            this.hotelInfo.hotelName,
            this.$global.visitUrl+'#'+shareUrl,
            this.hotelInfo.cover,
            this.hotelInfo.address,
          );
          this.searchHotelList(rsp)
        });
      },
      goTop(){
        let top = document.getElementById('full') ? document.getElementById('full').scrollTop : document.documentElement.scrollTop;
        let interval = setInterval(function () {
          if(document.getElementById('full').scrollTop == 0){
            clearInterval(interval)
          }else{
            document.getElementById('full').scrollTop = document.getElementById('full').scrollTop-15;
          }
        },10)
      },
      // 头部颜色
      scrollFunc(){
        let top = document.getElementById('full') ? document.getElementById('full').scrollTop : document.documentElement.scrollTop;
        if(top>30){
          this.colorState = true
        }else{
          this.colorState = false
        }
      },
      // 返回
      revert(num){//返回安卓原生页面或者h5页面
        if(this.$route.query.share == 1){
          this.$router.push('/');
        }else{
          this.$router.go(-1);
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .comment_out{
    .user_text{
      margin-bottom: 20px;
      font-size: 12px;
      font-weight: 500;
      color: #3E3E3E;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .top_user{
      margin-bottom: 20px;
    }
    .right_text{
      font-size: 12px;
      font-weight: 500;
      color: #A0A0A0;
    }
    .user_name{
      font-size: 14px;
      font-weight: bold;
      color: #3E3E3E;
    }
    .user_logo{
      .van-image{
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }
    }
    .co_score{
      span{
        font-size: 14px;
      }

      top: 22px;
      right: 13px;
      font-size: 21px;
      font-weight: bold;
      color: #20C278;
    }
    .co_title{
      margin-bottom: 26px;
      font-size: 17px;
      font-weight: bold;
      color: #3E3E3E;
    }
  }
  .hotel_name{
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .b_btn{
    .btn_cut{
      padding-left: 17px;
      div{
        width: 1px;
        height: 28px;
        background: #DEDEDE;
      }
    }
    .btn{
      >span{
        margin-right: 5px;
      }
      img{
        width: 15px;
        margin-right: 4px;
      }
      .sub{
        font-size: 9px;
      }
      line-height: 34px;
      font-size:13px;
      font-weight:bold;
      color:rgba(255,255,255,1);
      height:34px;
      background:rgba(31,193,119,1);
      border-radius:17px;
      padding: 0 23px;
    }
    a{
      img{
        width: 13px;
        /*margin-bottom: 6px;*/
      }
      text-align: center;
      font-size:9px;
      font-weight:500;
      color:rgba(61,61,61,1);
    }
    padding: 10px 21px 0 21px;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    width: 100%;
    height:51px;
    background:rgba(255,255,255,1);
    box-shadow: 0px 0px 2px 0px rgba(8, 1, 4, 0.3);
  }
  .card{
    .fw_out{
      div{
        &.notice{
          width: 100%;
          font-weight: bold;
          float: none;
          line-height: 43px;
          border-bottom: 1px solid #d0d0d0;
          margin-bottom: 0;
          .flex_1{
            margin-left: 12px;
            font-weight: 500;
          }
        }
        float: left;
        width: calc(100% / 3);
        margin-bottom: 15px;
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
      }
    }
    .see_more{
      font-size:13px;
      font-weight:500;
      color:rgba(32,193,119,1);
      margin-top: 5px;
    }
    .c_item{
      &.rooms_out{
        padding-top: 17px;
        &:first-child{
          padding-top: 0;
        }
      }
      .c_item_right{
        &.dis{
          >div{
            border:1px solid rgba(142,142,142,1);
            .r_top{
              background:rgba(142,142,142,1);
            }
            .r_sun{
              color:rgba(142,142,142,1);

            }
          }
          .num{display: none}
        }
        >div{
          .r_sun{
            height: 21px;
            line-height: 21px;
            font-size:10px;
            font-weight:500;
            color:rgba(32,193,119,1);
          }
          .r_top{
            height:31px;
            background:rgba(32,193,119,1);
            line-height: 31px;
            font-size:19px;
            font-weight:800;
            color:rgba(255,255,255,1);
          }
          margin-bottom: 5px;
          width:51px;
          height:52px;
          border:1px solid rgba(32,193,119,1);
          border-radius:7px;
          overflow: hidden;
        }
        .num{
          font-size:12px;
          font-weight:500;
          color:rgba(32,193,119,1);
        }
      }
      font-size:14px;
      color:rgba(62,62,62,1);
      &.around{
        padding-top: 15px;
        >.flex_1{
          margin-left: 19px;
        }
        .bot{
          .star{
            text-align: center;
            width:58px;
            height:22px;
            line-height: 22px;
            background:rgba(229,229,229,1);
            border-radius:2px;
            font-size:12px;
          }
          .price{
            font-size:14px;
          }
        }
        .top{
          font-size: 15px;
          margin-bottom: 12px;
        }
        .sub{
          color:rgba(62,62,62,1);
          margin-bottom: 11px;
        }
        .van-image{
          width: 111px;
          height: 83px;
          margin-right: 0;
        }
      }
      .top{
        font-weight:bold;
        margin-bottom: 3px;
      }
      .sub{
        font-size:12px;
        font-weight:500;
        color:rgba(142,142,142,1);
        margin-bottom: 3px;
      }
      .bot{
        font-weight:500;
        margin-top: 3px;
      }
      .van-image{
        width: 104px;
        height: 76px;
        margin-right: 12px;
      }
      &.bb_none{
        border-bottom: none;
      }
      padding: 0 0 17px 0;
      border-bottom: 1px solid #d0d0d0;
    }
    .c_types{
      >div{
        text-align: center;
        display: inline-block;
        padding: 0 8px;
        height:27px;
        border:1px solid rgba(160,160,160,1);
        border-radius:2px;
        margin-right: 7px;
        line-height: 27px;
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
        box-sizing: border-box;
        &.ac{
          background:rgba(236,255,246,1);
          border:1px solid rgba(32,193,119,1);
          color: #20C177;
        }
      }
      white-space: nowrap;
      overflow-x: auto;
      box-sizing: border-box;
      overflow-y: hidden;
      padding-bottom: 17px;
    }
    .c_head{
      padding-bottom: 14px;
      img{
        width: 6px;
        margin-left: 6px;
        position: relative;
        top: 1px;
      }
      .green{
        color: #20C177;
      }
      .sub{
        font-size:10px;
        font-weight:normal;
      }
      font-size:13px;
      font-weight:bold;
      color:rgba(62,62,62,1);
    }
    .top_addr{
      .d_f{
        img{
          width: 8px;
          margin-right: 10px;
        }
        margin-top: 8px;
      }
      font-size:13px;
      font-weight:500;
      color:rgba(62,62,62,1);
    }
    .top_item{
      div{
        float: left;
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
        text-align: center;
        height:24px;
        background:rgba(240,240,240,1);
        border:0px solid rgba(255,255,255,1);
        border-radius:2px;
        line-height: 24px;
        padding: 0 7px;
        margin: 0 7px 10px 0;
      }
      margin-bottom: 7px;
    }
    .top_head{
      &.mb_0{
        margin-bottom: 0;
      }
      .cur_p{
        font-size:11px;
        font-weight:500;
        color:rgba(32,193,119,1);
        vertical-align: middle;
        img{
          width: 6px;
          margin-left: 5px;
          vertical-align: middle;
          position: relative;
          top: -1px;
        }
      }
      .flex_1{
        font-size:11px;
        font-weight:500;
        margin-left: 5px;
      }
      margin-bottom: 15px;
      font-size:15px;
      font-weight:bold;
      color:rgba(62,62,62,1);
    }
    &.pb0{
      padding-bottom: 0;
      margin-bottom: 0;
    }
    margin-bottom: 10px;
    background-color: white;
    padding: 17px 20px;
    border-radius:7px;
  }
  .hi_content{
    top: -1rem;
    padding: 0 21px;
  }
  .full{
    overflow: auto;
    /*padding-bottom: 51px;*/
    box-sizing: border-box;
    background-color: #f0f0f0;
    &.over_h{
      overflow: hidden;
    }
  }
  .album{
    div{
      width: 21px;
      height: 12px;
      border-radius: 6px;
      text-align: center;
      line-height: 10px;
      background:rgba(255,255,255,0.75);
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 11px;
      }
    }
    width:49px;
    height:14px;
    background:rgba(26,26,26,0.75);
    border-radius:7px;
    right: 21px;
    bottom: 1.2rem;
    padding: 1px;
    font-size:8px;
    font-weight:500;
    color:rgba(255,255,255,1);
  }
  .td_head_angle{
    width: 11px !important;
    height: 18px;
    z-index: 222;
  }
  .td_head_box_out{
    top: 40px;
    right: 19px;
  }
  .td_head_box_out {
    background: none;
    .menu{
      background-color: rgba(0,0,0,0.5);
    }
  }
  .top_btn{
    width: 100%;
    padding: 8px 21px;
    z-index: 98;
    left: 0;
    box-sizing: border-box;
    .pointer{
      height: .3rem;
      overflow: hidden;
      text-align: right;
      span{
        width: .3rem;
        height: .3rem;
        display: inline-block;
        background: rgba(0, 0, 0, .7);
        transform: rotate(45deg);
        margin: .17rem .33rem 0 0;
        border-radius: 3px;

      }
    }
    img{
      &.no_l{
        margin-left: 0;
      }
      width: 29px;
      margin-left: 10px;
    }
  }
  .my-swipe >>> .van-swipe__indicators{
    bottom: 1.2rem;
    left: 0.56rem;
    transform: translateX(0);
  }
  .my-swipe >>> .van-swipe__indicators i{
    transition: all .2s linear;
    border-radius: .16rem;
  }
  .my-swipe >>> .van-swipe__indicators i.van-swipe__indicator--active{
    width: .5rem;
  }

</style>
